<template>
  <div>
    <!--列表-->
    <el-table
      :data="datas"
      style="width: 100%; margin-bottom: 20px"
      border
      highlight-current-row
      size="mini"
      :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }"
    >
    
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-table
            v-if="scope.row.Items && scope.row.Items.Items"
            size="small"
            highlight-current-row
            border
            :data="scope.row.Items && scope.row.Items.Items"
            style="width: 100%"
          >
            <el-table-column
              prop="Order"
              label="排序序号"
              align="center"
              show-overflow-tooltip
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="Name"
              label="名称"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Enabled"
              label="启用"
              align="center"
              width="80"
              show-overflow-tooltip
            >
            <template slot-scope="scope1">
                <el-tag type="success" v-if="scope1.row.Enabled">启用</el-tag>
                <el-tag v-else type="danger">停用</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="FrequecyRequire"
              label="训练强度"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="TimeMin"
              show-overflow-tooltip
              label="运动总量(时间)"
              sortable
              width="130"
            >
              <template slot-scope="scope1">
                {{ scope1.row.TimeMin }}分{{ scope1.row.TimeSec }}秒
              </template>
            </el-table-column>
            <el-table-column
              prop="Objective"
              label="训练目的"
              align="center"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="ActionDesc"
              label="动作要领"
              align="center"
              min-width="200"
            >
            </el-table-column>
            <el-table-column
              prop="ActionLoad"
              show-overflow-tooltip
              label="动作负荷"
              sortable
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="Equipment"
              show-overflow-tooltip
              label="器材要求"
              sortable
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="Image"
              label="示例图片"
              show-overflow-tooltip
              align="center"
              width="150"
            >
              <template slot-scope="scope">
                <viewer v-if="scope.row.Image">
                  <img
                    v-if="scope.row.Image"
                    :src="baseUrl + scope.row.Image"
                    :key="scope.row.Image"
                    style="height: 40px"
                  />
                </viewer>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="handleDeleteItem(scope.row)"
                  >删除</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="handleEditItem(scope.row)"
                  >修改</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <span
            style="font-size: 16px; font-weight: bold; text-align: center"
            v-else
            >该项目未包含训练项目</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="Order"
        label="序号"
        sortable
       width="80"
      >
      </el-table-column>
      <el-table-column
        prop="PrescType"
        label="测试项得分范围"
        show-overflow-tooltip
        sortable
        min-width="120"
      >
        <template slot-scope="scope">
          {{ format.prescTypeFormat(scope.row.PrescType) }}(≤{{
            scope.row.LessEqualScore
          }})
        </template>
      </el-table-column>
      <el-table-column
        prop="ActionWay"
        label="类别"
        how-overflow-tooltip
        sortable
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="ActionModule"
        label="提升内容"
        how-overflow-tooltip
        sortable
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="ActionLoad"
        show-overflow-tooltip
        label="运动负荷"
        sortable
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="Equipment"
        show-overflow-tooltip
        label="器材要求"
        sortable
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="ActionFrequency"
        show-overflow-tooltip
        label="运动频次"
        sortable
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="TimeMin"
        show-overflow-tooltip
        label="运动总量(时间)"
        sortable
        width="130"
      >
        <template slot-scope="scope">
          {{ scope.row.TimeMin }}分{{ scope.row.TimeSec }}秒
        </template>
      </el-table-column>
      <el-table-column
        prop="Image"
        label="示例图片"
        show-overflow-tooltip
        align="center"
        width="150"
      >
        <template slot-scope="scope">
          <viewer v-if="scope.row.Image">
            <img
              v-if="scope.row.Image"
              :src="baseUrl + scope.row.Image"
              :key="scope.row.Image"
              style="height: 40px"
            />
          </viewer>
        </template>
      </el-table-column>
      <el-table-column
        prop="Desc"
        show-overflow-tooltip
        label="备注"
        sortable
        width="100"
      >
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleDelete(scope.row)"
            >删除</el-button
          >
          <el-button type="text" size="small" @click="handleEdit(scope.row)"
            >修改</el-button
          >
          <el-button type="text" size="small" @click="handleAddItem(scope.row)"
            >添加训练项目</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="pageSizesList"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :current-page.sync="pageIndex"
    ></el-pagination>
    <prescriptionEditor ref="prescriptionEditor" @update="getPageData" />
    <prescriptionItemEditor
      ref="prescriptionItemEditor"
      @update="getPageData"
    />
  </div>
</template>
<script>
import { getPageList, deleteObj } from "@/api/library/prescriptionType";
import { deleteObj as deleteItem } from "@/api/library/prescriptionItem";
import prescriptionEditor from "./prescriptionEditor";
import prescriptionItemEditor from "./prescriptionItemEditor";
export default {
  components: { prescriptionEditor, prescriptionItemEditor },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.pageIndex = 1;
        this.getPageData();
      },
      immediate: true,
    },
  },
  data() {
    return {
      datas: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
    };
  },

  methods: {
    getPageData() {
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        ...this.filter,
      };
      getPageList(data).then((response) => {
        if (response) {
          this.datas = response.Items;
          this.total = response.Total || response.Items.length;
        }
      });
    },
    handleSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getPageData());
    },
    handleCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.getPageData();
    },
    //删除
    handleDelete: function (row) {
      this.$confirm("删除后影响处方报告输出，确认删除该记录吗?", "提示", {
        type: "warning",
      }).then(() => {
        let para = { key: row.Key };
        deleteObj(para).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功");
            this.getPageData();
          }
        });
      });
    },
    handleCreate: function () {
      this.$refs.prescriptionEditor.handleCreate();
    },
    handleEdit: function (row) {
      this.$refs.prescriptionEditor.handleUpdate(row);
    },
    handleView: function (row) {
      this.$refs.prescriptionEditor.handleView(row);
    },
    handleAddItem: function (row) {
      this.$refs.prescriptionItemEditor.handleCreate(row.Key);
    },
    handleEditItem: function (row) {
      this.$refs.prescriptionItemEditor.handleUpdate(row);
    },
    handleDeleteItem: function (row) {
      this.$confirm("删除后影响处方报告输出，确认删除该记录吗?", "提示", {
        type: "warning",
      }).then(() => {
        let para = { key: row.Key };
        deleteItem(para).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功");
            this.getPageData();
          }
        });
      });
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.filter-disease-selector {
  /deep/.el-select {
    width: 150px !important;
  }
}
</style>